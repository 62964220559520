import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterLink, RouterOutlet } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {
  AsyncStateOverlayModule,
  CustomTranslatorModule,
  PagesModule,
  SharedModule,
  SurveyModule,
} from 'ngx-esprio-shared';
import { AppAuthUIModule } from './auth-ui/app-auth-ui.module';
import { DashboardComponent } from './dashboard-ui/dashboard.components';
import { LandingPageComponent } from './landing-page-ui/landing-page.component';
import { OnDemandSurveyComponent } from './survey-ui/on-demand-survey/on-demand-survey.component';
import { AppSurveyModule } from './survey/app-survey.module';
import { FlowSituationCardComponent } from './dashboard-ui/flow-situation-card/flow-situation-card.component';
import { NutritionGoalsComponent } from './dashboard-ui/nutrition-goals/nutrition-goals.component';
import { NutritionalStrugglesComponent } from './dashboard-ui/nutritional-struggles/nutritional-struggles.component';
import { LinkPreviewComponent } from './dashboard-ui/link-preview/link-preview.component';

@NgModule({
  declarations: [DashboardComponent, LandingPageComponent, OnDemandSurveyComponent, FlowSituationCardComponent, NutritionGoalsComponent, NutritionalStrugglesComponent, LinkPreviewComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CarouselModule.forRoot(),
    CdkTableModule,
    InlineSVGModule,
    BsDatepickerModule,
    RouterLink,
    RouterOutlet,
    CommonModule,
    CustomTranslatorModule,
    AppAuthUIModule,
    AsyncStateOverlayModule,
    AppSurveyModule,
    SurveyModule,
    SharedModule,
    PagesModule,
  ],
  providers: [],
})
export class ViewsModule {}
