import { AppEnvironment, Environment, OptionalApplicationFeature } from 'ngx-esprio-shared';

export const environment: AppEnvironment = {
  production: false,
  environment: Environment.webk,
  apiBaseUrl: 'https://dev.ernährungsbegleiter.de/api/v1',
  // apiBaseUrl: 'http://localhost:3000/api/v1',
  monitoringApp: 'https://monitoring.dev.esprio.de',
  userApp: 'https://dev.ernährungsbegleiter.de',
  useMockServices: false,
  defaultLanguage: 'de',
  disabledAppFeatures: [
    OptionalApplicationFeature.LanguagePicker,
    OptionalApplicationFeature.MonitoringLandingPage,
    OptionalApplicationFeature.ConsultantConnection,
    OptionalApplicationFeature.SignUpPage,
    OptionalApplicationFeature.RelativeAccountConnection,
  ],
};
