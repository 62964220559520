import { Component, Inject, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslatePipe } from '@ngx-translate/core';
import * as moment from 'moment';
import {
  BaseAppState,
  ENVIRONMENT,
  Environment,
  WeightHistorySelectors,
  UserDetails,
} from 'ngx-esprio-shared';
import { combineLatest, map, Observable } from 'rxjs';

@Component({
  selector: 'app-nutrition-goals',
  templateUrl: './nutrition-goals.component.html',
  styleUrls: ['./nutrition-goals.component.scss'],
})
export class NutritionGoalsComponent {
  @Input() user: UserDetails | null = null;

  public weightData$ = this.store.select(WeightHistorySelectors.getData);

  constructor(
    @Inject(ENVIRONMENT) public environment: Environment,
    private store: Store<BaseAppState>,
    private translatePipe: TranslatePipe
  ) {}

  public lastTwoWeightEntries$ = this.weightData$.pipe(
    map((data) => {
      const sorted = [...data].sort(
        (a, b) => moment(b.timestamp).valueOf() - moment(a.timestamp).valueOf()
      );
      return sorted.slice(0, 2);
    })
  );

  public effectiveWeights$ = this.lastTwoWeightEntries$.pipe(
    map((lastTwoEntries) => {
      const coreData = this.user?.coreData;
      if (!coreData) {
        return {
          mostRecentWeight: 0,
          secondMostRecentWeight: 0,
          heightInM: 1,
        };
      }

      let mostRecentWeight: number;
      let secondMostRecentWeight: number;

      if (lastTwoEntries.length >= 2) {
        mostRecentWeight = lastTwoEntries[0].weight;
        secondMostRecentWeight = lastTwoEntries[1].weight;
      } else {
        mostRecentWeight = coreData.initialWeightInKg;
        secondMostRecentWeight = coreData.weightSixMonthsAgoInKg;
      }

      const heightInM = coreData.bodySizeInCm / 100;

      return {
        mostRecentWeight,
        secondMostRecentWeight,
        heightInM,
      };
    })
  );

  public bmi$ = this.effectiveWeights$.pipe(
    map(({ mostRecentWeight, heightInM }) => {
      return Math.floor(mostRecentWeight / (heightInM * heightInM));
    })
  );

  public weightChangePercentage$ = this.effectiveWeights$.pipe(
    map(({ mostRecentWeight, secondMostRecentWeight }) => {
      return Math.floor(100 - (secondMostRecentWeight / mostRecentWeight) * 100);
    })
  );
  
  public getFlowSituation$(): Observable<{
    nutritionGoalTitle: string;
    nutritionGoalText: string;
  }> {
    return combineLatest([this.bmi$, this.weightChangePercentage$]).pipe(
      map(([bmi, weightChangePercentage]) => {
        if (bmi < 21 && weightChangePercentage > 0) {
          return {
            nutritionGoalTitle: this.translatePipe.transform('fast_lane.webk.additional_title'),
            nutritionGoalText: this.translatePipe.transform(
              'fast_lane.webk.green_flow_additional_text_1',
              { weightChangePercentage, bmi }
            ),
          };
        }
        if (bmi >= 21 && bmi <= 23 && weightChangePercentage > 0) {
          return {
            nutritionGoalTitle: this.translatePipe.transform('fast_lane.webk.additional_title'),
            nutritionGoalText: this.translatePipe.transform(
              'fast_lane.webk.green_flow_additional_text_2',
              { weightChangePercentage, bmi }
            ),
          };
        }
        if (bmi >= 23 && weightChangePercentage > 0) {
          return {
            nutritionGoalTitle: this.translatePipe.transform('fast_lane.webk.additional_title'),
            nutritionGoalText: this.translatePipe.transform(
              'fast_lane.webk.green_flow_additional_text_3',
              { weightChangePercentage, bmi }
            ),
          };
        }
        if (weightChangePercentage === 0 && bmi >= 21 && bmi <= 23) {
          return {
            nutritionGoalTitle: this.translatePipe.transform('fast_lane.webk.additional_title'),
            nutritionGoalText: this.translatePipe.transform(
              'fast_lane.webk.yellow_flow_additional_text_1',
              { weightChangePercentage, bmi }
            ),
          };
        }
        if (weightChangePercentage === 0 && bmi >= 23 && bmi <= 25) {
          return {
            nutritionGoalTitle: this.translatePipe.transform('fast_lane.webk.additional_title'),
            nutritionGoalText: this.translatePipe.transform(
              'fast_lane.webk.yellow_flow_additional_text_2',
              { weightChangePercentage, bmi }
            ),
          };
        }
        if (weightChangePercentage === 0 && bmi > 25) {
          return {
            nutritionGoalTitle: this.translatePipe.transform('fast_lane.webk.additional_title'),
            nutritionGoalText: this.translatePipe.transform(
              'fast_lane.webk.yellow_flow_additional_text_3',
              { weightChangePercentage, bmi }
            ),
          };
        }
        if (weightChangePercentage === 0 && bmi < 21) {
          return {
            nutritionGoalTitle: this.translatePipe.transform('fast_lane.webk.additional_title'),
            nutritionGoalText: this.translatePipe.transform(
              'fast_lane.webk.yellow_flow_additional_text_4',
              { weightChangePercentage, bmi }
            ),
          };
        }
        if (weightChangePercentage < 0 && weightChangePercentage >= -5) {
          return {
            nutritionGoalTitle: this.translatePipe.transform('fast_lane.webk.additional_title'),
            nutritionGoalText: this.translatePipe.transform(
              'fast_lane.webk.red_flow_additional_text_1',
              { weightChangePercentage, bmi }
            ),
          };
        }
        if (weightChangePercentage >= -10 && weightChangePercentage < -5) {
          return {
            nutritionGoalTitle: this.translatePipe.transform('fast_lane.webk.additional_title'),
            nutritionGoalText: this.translatePipe.transform(
              'fast_lane.webk.red_flow_additional_text_2',
              { weightChangePercentage, bmi }
            ),
          };
        }
        if (weightChangePercentage >= -15 && weightChangePercentage < -10) {
          return {
            nutritionGoalTitle: this.translatePipe.transform('fast_lane.webk.additional_title'),
            nutritionGoalText: this.translatePipe.transform(
              'fast_lane.webk.red_flow_additional_text_3',
              { weightChangePercentage, bmi }
            ),
          };
        }
        if (weightChangePercentage < -15) {
          return {
            nutritionGoalTitle: this.translatePipe.transform('fast_lane.webk.additional_title'),
            nutritionGoalText: this.translatePipe.transform(
              'fast_lane.webk.red_flow_additional_text_4',
              { weightChangePercentage, bmi }
            ),
          };
        }
        return {
          nutritionGoalTitle: this.translatePipe.transform('fast_lane.webk.additional_title'),
          nutritionGoalText: this.translatePipe.transform('fast_lane.webk.default_flow_additional_text'),
        };
      })
    );
  }
}