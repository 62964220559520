<div *ngIf="getFlowSituation$() | async as flowSituation">
  <div *ngIf="user as userDetails"
       [class]="'result d-flex p-2 result-' + (userDetails.flow || 'fallback')">
    <div class="d-flex row w-100">
      <div class="d-flex col-12 flex-column justify-content-end align-items-center">
        <div class="d-flex justify-content-center flex-column mt-4">
          <h3 class="px-5 text-center">
            {{ 'fast_lane.webk.situation' | translate }}
          </h3>
          <p class="px-5 text-center" [innerHTML]="flowSituation.yourSituation"></p>
          <div class="d-flex justify-content-center flex-column flex-sm-row">
            <button class="btn btn-outline-dark btn-lg my-1" (click)="handleDownloadResults()">
              {{ 'buttons.download_results' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>