import { CdkStepper } from '@angular/cdk/stepper';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { WizardStepOption } from '../multi-option-wizard-step/multi-option-wizard-step.component';
import { Store } from '@ngrx/store';
import { BaseAppState, goToLogin } from 'ngx-esprio-shared';
import { Directionality } from '@angular/cdk/bidi';

export interface WizardStep {
  title: string;
  formGroupName?: string;
  imageUrl?: string;
  message?: string;
  buttonPreviousStepTitle?: string;
  buttonNextStepTitle?: string;
  skipStepLinkText?: string;
  options?: WizardStepOption[];
  multiSelect?: boolean;
  ignoreValidation?: boolean;
}

@Component({
  selector: 'app-user-core-data-wizard',
  templateUrl: './user-core-data-wizard.component.html',
  providers: [{ provide: CdkStepper, useExisting: EditUserCoreDataWizardComponent }],
})
export class EditUserCoreDataWizardComponent extends CdkStepper {
  @Input() public stepData: WizardStep[] = [];
  @Output() public finish = new EventEmitter();

  constructor(
    private store: Store<BaseAppState>,
    dir: Directionality,
    changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef<HTMLElement>
  ) {
    super(dir, changeDetectorRef, elementRef);
  }

  public get step(): WizardStep | null {
    return this.stepData?.[this.selectedIndex] ?? null;
  }

  public get isLastStep(): boolean {
    return this.selectedIndex >= this.steps.length - 1;
  }

  public get ignoreValidation(): boolean {
    return Boolean(this.step?.ignoreValidation);
  }

  public get selectedFormGroup(): FormGroup | null {
    return (this.selected?.stepControl as FormGroup) ?? null;
  }

  public handleNext(ignoreValidation = this.ignoreValidation): void {
    if (!ignoreValidation && this.selectedFormGroup?.invalid) {
      return;
    }
    if (this.isLastStep) {
      this.finish.emit();
    } else {
      this.next();
    }
  }

  public handelLoginClick() {
    this.store.dispatch(goToLogin ());
  }

  public handlePrevious(): void {
    this.previous();
  }

  public handleSkip(): void {
    this.handleNext(true);
  }
}
