import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BaseAppState, LandingpageType, NavbarSelectors, goToLogin, goToSignUp, goToUpdateUserCoreData, setAnonymous } from 'ngx-esprio-shared';
import { environment } from 'projects/webk/src/environments/environment';

@Component({
  selector: 'app-landing-page',
  templateUrl: 'landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements AfterViewInit, OnInit {
  @ViewChild('slider', { static: false }) slider: ElementRef;
  public initialSlidesOrder: string[] = ['daniel', 'christoph', 'markus'];
  public slidesOrder: string[];
  public isTransitioning = false;
  public selectedLandingPage$ = this.store.pipe(select(NavbarSelectors.selectSelectedLandingPage));
  private slideWidth: number;
  public landingpage = LandingpageType;

  private boundNextSlide: () => void;
  private boundPrevSlide: () => void;

  constructor(private store: Store<BaseAppState>, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.prepareSlides();

    this.boundNextSlide = this.nextSlide.bind(this);
    this.boundPrevSlide = this.prevSlide.bind(this);
  }

  ngAfterViewInit() {
    const middleIndex = Math.floor(this.slidesOrder.length / 2 - 1);
    const prevIndex = (middleIndex - 1 + this.slidesOrder.length) % this.slidesOrder.length;
    const nextIndex = (middleIndex + 1) % this.slidesOrder.length;

    this.updateCardClassesAndHandlers(
      this.slider.nativeElement.children,
      middleIndex,
      prevIndex,
      nextIndex
    );
  }

  public handleStartMedipolisLane() {
    this.store.dispatch(setAnonymous());
    this.store.dispatch(goToUpdateUserCoreData());
  }

  public noImage(event): void {
    event.target.src = '/assets/images/default_profile.svg';
  }

  public handleLoginClick() {
    this.store.dispatch(goToLogin());
  }

  handleSignUpMonitoringApp(event: Event) {
    event.preventDefault();
    window.location.href = `${environment.monitoringApp}/auth/sign-up`;
  }

  public toggleAnswer(event: Event): void {
    const questionElement = event.currentTarget as HTMLElement;
    if (questionElement) {
      const answerElement: HTMLElement | null = questionElement.nextElementSibling as HTMLElement;

      if (answerElement) {
        if (answerElement.classList.contains('hidden')) {
          answerElement.style.maxHeight = '500px';
          answerElement.classList.remove('hidden');
        } else {
          answerElement.style.maxHeight = '0';
          answerElement.classList.add('hidden');
        }
      }

      const plusIcon: HTMLElement | null = questionElement.querySelector('.plus-icon');
      const minusIcon: HTMLElement | null = questionElement.querySelector('.minus-icon');

      if (plusIcon && minusIcon) {
        plusIcon.classList.toggle('hidden');
        minusIcon.classList.toggle('hidden');
      }
    }
  }

  public nextSlide() {
    if (this.slider && !this.isTransitioning) {
      this.setSlideWidth();
      this.isTransitioning = true;
      const sliderElement = this.slider.nativeElement;
      const firstCard = sliderElement.firstElementChild;

      this.calculateAndUpdateMiddleCardForNext();
      this.renderer.setStyle(sliderElement, 'transform', `translateX(-${this.slideWidth}px)`);

      setTimeout(() => {
        this.renderer.setStyle(sliderElement, 'transition', 'none');
        this.renderer.appendChild(sliderElement, firstCard);
        this.renderer.setStyle(sliderElement, 'transform', 'translateX(0)');
        setTimeout(() => {
          this.renderer.setStyle(sliderElement, 'transition', '');
          this.isTransitioning = false;
        }, 300);
      }, 300);
    }
  }

  public prevSlide() {
    if (this.slider && !this.isTransitioning) {
      this.setSlideWidth();
      this.isTransitioning = true;
      const sliderElement = this.slider.nativeElement;
      const lastCard = sliderElement.lastElementChild;

      this.calculateAndUpdateMiddleCardForPrev();
      this.renderer.setStyle(sliderElement, 'transform', `translateX(${this.slideWidth}px)`);

      setTimeout(() => {
        this.renderer.setStyle(sliderElement, 'transition', 'none');
        this.renderer.insertBefore(sliderElement, lastCard, sliderElement.firstElementChild);
        this.renderer.setStyle(sliderElement, 'transform', 'translateX(0)');
        setTimeout(() => {
          this.renderer.setStyle(sliderElement, 'transition', '');
          this.isTransitioning = false;
        }, 300);
      }, 300);
    }
  }

  public handleSignUpPage() {
    this.store.dispatch(goToSignUp());
  }

  private prepareSlides() {
    this.slidesOrder = this.initialSlidesOrder;

    while (this.slidesOrder.length <= 6 || this.slidesOrder.length % 2 !== 0) {
      this.slidesOrder = [...this.slidesOrder, ...this.initialSlidesOrder];
    }
  }

  private setSlideWidth() {
    const firstCard = this.slider.nativeElement.children[0];
    if (firstCard) {
      this.slideWidth = firstCard.offsetWidth + 128;
    }
  }

  private calculateAndUpdateMiddleCardForNext() {
    const cards = this.slider.nativeElement.children;
    const middleIndex = Math.floor(cards.length / 2 - 1);
    const newMiddleIndex = (middleIndex + 1) % cards.length;
    const prevIndex = (newMiddleIndex - 1 + cards.length) % cards.length;
    const nextIndex = (newMiddleIndex + 1) % cards.length;

    this.updateCardClassesAndHandlers(cards, newMiddleIndex, prevIndex, nextIndex);
  }

  private calculateAndUpdateMiddleCardForPrev() {
    const cards = this.slider.nativeElement.children;
    const middleIndex = Math.floor(cards.length / 2 - 1);
    const newMiddleIndex = (middleIndex - 1 + cards.length) % cards.length;
    const prevIndex = (newMiddleIndex - 1 + cards.length) % cards.length;
    const nextIndex = (newMiddleIndex + 1) % cards.length;

    this.updateCardClassesAndHandlers(cards, newMiddleIndex, prevIndex, nextIndex);
  }

  private updateCardClassesAndHandlers(
    cards: HTMLCollection,
    newMiddleIndex: number,
    prevIndex: number,
    nextIndex: number
  ) {
    Array.from(cards).forEach((card, index) => {
      this.renderer.removeClass(card, 'middle-card');
      this.renderer.removeClass(card, 'prev-card');
      this.renderer.removeClass(card, 'next-card');
      card.removeEventListener('click', this.boundPrevSlide);
      card.removeEventListener('click', this.boundNextSlide);

      if (index === newMiddleIndex) {
        this.renderer.addClass(card, 'middle-card');
      } else if (index === prevIndex) {
        this.renderer.addClass(card, 'prev-card');
        card.addEventListener('click', this.boundPrevSlide);
      } else if (index === nextIndex) {
        this.renderer.addClass(card, 'next-card');
        card.addEventListener('click', this.boundNextSlide);
      }
    });
  }
}
