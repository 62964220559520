<a [href]="preview.siteUrl" target="_blank" class="link mb-5" *ngIf="(linkPreviewData$ | async) as preview">
  <h3>{{ preview.title }}</h3>

  <div class="preview-container">
    <img
      *ngIf="getImageUrl(preview.image); else noImage"
      [src]="getImageUrl(preview.image)"
      class="preview-image"
    />
    <ng-template #noImage>
      <img
        src="../../../../assets/images/LogoMain.svg"
        class="preview-image"
        alt="Default Image"
      />
    </ng-template>
    <p class="description">
      {{ preview.description }}
    </p>
  </div>
</a>
