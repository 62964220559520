import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import {
  AuthSelectors,
  BaseAppState,
  ModalsActions,
  RelativeAccountActions,
  SurveyApiActions,
  SurveySelectors,
  WeightHistoryApiActions,
  WeightHistorySelectors,
  getIsRelativeUserAccount,
  goToSurvey,
  UsersActions,
  UsersSelectors
} from 'ngx-esprio-shared';
import { BehaviorSubject, take } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  public userDetails$ = this.store.select(UsersSelectors.getUserDetails);

  public readonly asyncProcessKeys = [SurveyApiActions.createSubmission.type];

  public weightData$ = this.store.select(WeightHistorySelectors.getData);
  public basicWebkData$ = this.store.select(SurveySelectors.getBasicWebkHistoryData);
  public date$ = new BehaviorSubject<Date>(moment().startOf('month').toDate());
  public dataAvailability$ = this.store.select(SurveySelectors.getDataAvailability);
  public isRelativeAccount$ = this.store.select(getIsRelativeUserAccount);

  public getLoggedInUserId$ = this.store.select(AuthSelectors.getLoggedInUserId);

  public date = moment().startOf('month').toDate();

  constructor(private store: Store<BaseAppState>) {}

  public ngOnInit(): void {
    this.fetchChartData();
    this.loadPatientsData();
    this.store.dispatch(SurveyApiActions.checkDataAvailability());
    this.fetchUserDetails();
  }

  public ngAfterViewInit(): void {
    this.store.dispatch(ModalsActions.openAddWeightDataModalIfSubmissionNeeded());
    this.store.dispatch(SurveyApiActions.getBasicWebkHistoryData());
  }

  public handleDateChange(date: Date): void {
    this.date$.next(date);
    this.fetchChartData();
  }

  public handleNewSurveyClick(): void {
    this.store.dispatch(SurveyApiActions.createSubmission());
  }

  public handleGoToSurveyAnalysis(submissionId: string): void {
    this.store.dispatch(goToSurvey({ submissionId }));
  }

  public fetchChartData(): void {
    this.store.dispatch(WeightHistoryApiActions.getWeightHistoryData());
    this.store.dispatch(SurveyApiActions.getBasicWebkHistoryData());
  }

  public fetchUserDetails(): void {
    this.getLoggedInUserId$.pipe(take(1)).subscribe((userId) => {
      if (userId) {
        this.store.dispatch(UsersActions.getUserDetails({ userId }));
      }
    });
  }

  private loadPatientsData(): void {
    this.isRelativeAccount$.pipe(take(1)).subscribe((isRelativeAccount) => {
      if (isRelativeAccount) {
        this.store.dispatch(RelativeAccountActions.getRelativePatients());
      }
    });
  }
}