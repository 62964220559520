<h3>{{ 'dashboard.nutritional_struggles.title' | translate }}</h3>
<p class="text-muted">
  {{ 'dashboard.nutritional_struggles.subtitle' | translate }}
</p>

<ul *ngIf="(symptomLinks$ | async) as links">
  <div *ngFor="let link of links">
    <app-link-preview [url]="link.url"></app-link-preview>
  </div>
</ul>

