import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ImageObject, LinkPreviewActions, LinkPreviewData, LinkPreviewSelectors } from 'ngx-esprio-shared';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-link-preview',
  templateUrl: './link-preview.component.html',
  styleUrls: ['./link-preview.component.scss'],
})
export class LinkPreviewComponent implements OnInit {
  @Input() url: string;

  linkPreviewData$: Observable<LinkPreviewData | null>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    if (this.url) {
      this.store.dispatch(LinkPreviewActions.loadLinkPreview({ url: this.url }));
    }

    this.linkPreviewData$ = this.store.select(LinkPreviewSelectors.getPreviewByUrl(this.url));
  }

  public getImageUrl(image: ImageObject[] | string): string | null {
    if (!image) {
      return null;
    }

    if (typeof image === 'string') {
      return image || null;
    }

    if (Array.isArray(image) && image.length > 0) {
      const first = image[0];
      if (first && first.url) {
        return first.url;
      }
    }

    return null;
  }
}
