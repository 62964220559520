import { Component, Input, OnInit } from '@angular/core';
import { BasicWebkData, BasicWebkSubject } from 'ngx-esprio-shared';
import { BehaviorSubject } from 'rxjs';

interface SymptomLink {
  url: string;
}

const SYMPTOM_LINKS: Record<BasicWebkSubject, SymptomLink> = {
  [BasicWebkSubject.AppetiteLoss]: {
    url: 'https://www.was-essen-bei-krebs.de/was-essen-bei/beschwerden/appetitlosigkeit/',
  },
  [BasicWebkSubject.AbdominalCramps]: {
    url: 'https://www.was-essen-bei-krebs.de/bauchkraempfe/',
  },
  [BasicWebkSubject.Bloating]: {
    url: 'https://www.was-essen-bei-krebs.de/was-essen-bei/beschwerden/blaehungen/',
  },
  [BasicWebkSubject.LowThirst]: {
    url: 'https://www.was-essen-bei-krebs.de/2021/04/20/wie-trinke-ich-ausreichend-bei-krebs/',
  },
  [BasicWebkSubject.DumpingSyndrome]: {
    url: 'https://www.was-essen-bei-krebs.de/was-essen-bei/beschwerden/dumpingsyndrom/',
  },
  [BasicWebkSubject.Diarrhea]: {
    url: 'https://www.was-essen-bei-krebs.de/was-essen-bei/beschwerden/durchfall/',
  },
  [BasicWebkSubject.FattyStool]: {
    url: 'https://www.was-essen-bei-krebs.de/was-essen-bei/beschwerden/fettstuhl-gestoerte-fettverdauung/',
  },
  [BasicWebkSubject.TasteChanges]: {
    url: 'https://www.was-essen-bei-krebs.de/was-essen-bei/beschwerden/geschmacksveraenderungen/',
  },
  [BasicWebkSubject.ChewingSwallowing]: {
    url: 'https://www.was-essen-bei-krebs.de/was-essen-bei/beschwerden/kau-und-schluckbeschwerden/',
  },
  [BasicWebkSubject.OralInflammation]: {
    url: 'https://www.was-essen-bei-krebs.de/was-essen-bei/beschwerden/mukositis-stomatitis/',
  },
  [BasicWebkSubject.AcidIndigestion]: {
    url: 'https://www.was-essen-bei-krebs.de/was-essen-bei/beschwerden/sodbrennen/',
  },
  [BasicWebkSubject.NauseaVomiting]: {
    url: 'https://www.was-essen-bei-krebs.de/was-essen-bei/beschwerden/uebelkeit-und-erbrechen/',
  },
  [BasicWebkSubject.SalivationChanges]: {
    url: 'https://www.was-essen-bei-krebs.de/was-essen-bei/beschwerden/veraenderungen-des-speichelflusses/',
  },
  [BasicWebkSubject.Constipation]: {
    url: 'https://www.was-essen-bei-krebs.de/was-essen-bei/beschwerden/verstopfung/',
  },
  [BasicWebkSubject.FeelingOfFullness]: {
    url: 'https://www.was-essen-bei-krebs.de/was-essen-bei/beschwerden/voellegefuehl/',
  },
};

@Component({
  selector: 'app-nutritional-struggles',
  templateUrl: './nutritional-struggles.component.html',
  styleUrls: ['./nutritional-struggles.component.scss'],
})
export class NutritionalStrugglesComponent implements OnInit {
  @Input() basicWebkData: BasicWebkData[] | null;
  symptomLinks$: BehaviorSubject<SymptomLink[]> = new BehaviorSubject([]);

  ngOnInit(): void {
    this.setupData();
    console.log('[NutritionalStrugglesComponent] ngOnInit called');
  }

  private setupData() {
    if (this.basicWebkData && this.basicWebkData.length > 0) {
      const maxTimestamp = Math.max(
        ...this.basicWebkData.map((d) => new Date(d.timestamp).getTime())
      );
      const newestItems = this.basicWebkData.filter(
        (d) => new Date(d.timestamp).getTime() === maxTimestamp
      );

      const newestItemsWithIndex = newestItems.map((item, idx) => ({ item, idx }));
      newestItemsWithIndex.sort((a, b) => {
        if (b.item.value !== a.item.value) {
          return b.item.value - a.item.value;
        }
        return a.idx - b.idx;
      });

      const finalLinks = newestItemsWithIndex
        .filter((x) => x.item.value > 0)
        .map((x) => SYMPTOM_LINKS[x.item.subject])
        .filter(Boolean);

      console.log('[NutritionalStrugglesComponent] finalLinks from mapping:', finalLinks);

      this.symptomLinks$.next(finalLinks);
    }
  }
}
