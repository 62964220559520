import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslatePipe } from '@ngx-translate/core';
import * as moment from 'moment';
import {
  AnswerDisplayPipe,
  BaseAppState,
  createPdfFromResultsBaseline,
  createPdfFromResultsRed,
  createPdfFromResultsYellow,
  ENVIRONMENT,
  Environment,
  getUserLanguage,
  SubmissionAnswer,
  SurveySelectors,
  UserDetails,
  WeightHistorySelectors,
} from 'ngx-esprio-shared';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { BehaviorSubject, combineLatest, map, Observable, take, tap } from 'rxjs';

@Component({
  selector: 'app-flow-situation-card',
  templateUrl: './flow-situation-card.component.html',
  styleUrls: ['./flow-situation-card.component.scss'],
})
export class FlowSituationCardComponent implements OnChanges {
  @Input() user: UserDetails | null = null;

  public weightData$ = this.store.select(WeightHistorySelectors.getData);
  public submissionResult$ = this.store.select(SurveySelectors.getAnonymousSubmissionResult);
  public language$ = this.store.select(getUserLanguage);

  public data$ = new BehaviorSubject<SubmissionAnswer[]>([]);
  public date: Date = moment().toDate();

  constructor(
    @Inject(ENVIRONMENT) public environment: Environment,
    private store: Store<BaseAppState>,
    private answerPipe: AnswerDisplayPipe,
    private translatePipe: TranslatePipe
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['user']) {
      // react to input changes if needed
    }
  }

  public lastTwoWeightEntries$ = this.weightData$.pipe(
    map((data) => {
      const sorted = [...data].sort(
        (a, b) => moment(b.timestamp).valueOf() - moment(a.timestamp).valueOf()
      );
      return sorted.slice(0, 2);
    })
  );

  public effectiveWeights$ = this.lastTwoWeightEntries$.pipe(
    map((lastTwoEntries) => {
      const coreData = this.user?.coreData;
      if (!coreData) {
        return { mostRecentWeight: 0, secondMostRecentWeight: 0, heightInM: 1 };
      }
      let mostRecentWeight: number;
      let secondMostRecentWeight: number;
      if (lastTwoEntries.length >= 2) {
        mostRecentWeight = lastTwoEntries[0].weight;
        secondMostRecentWeight = lastTwoEntries[1].weight;
      } else {
        mostRecentWeight = coreData.initialWeightInKg;
        secondMostRecentWeight = coreData.weightSixMonthsAgoInKg;
      }
      const heightInM = coreData.bodySizeInCm / 100;
      return { mostRecentWeight, secondMostRecentWeight, heightInM };
    })
  );

  public bmi$ = this.effectiveWeights$.pipe(
    map(({ mostRecentWeight, heightInM }) => Math.floor(mostRecentWeight / (heightInM * heightInM)))
  );

  public weightChangePercentage$ = this.effectiveWeights$.pipe(
    map(({ mostRecentWeight, secondMostRecentWeight }) =>
      Math.floor(100 - (secondMostRecentWeight / mostRecentWeight) * 100)
    )
  );

  public getFlowSituation$(): Observable<{ yourSituation: string }> {
    return combineLatest([this.bmi$, this.weightChangePercentage$]).pipe(
      map(([bmi, weightChangePercentage]) => {
        if (bmi < 21 && weightChangePercentage > 0) {
          return {
            yourSituation: this.translatePipe.transform('fast_lane.webk.text_green_flow_1', {
              weightChangePercentage,
              bmi,
            }),
          };
        }
        if (bmi >= 21 && bmi <= 23 && weightChangePercentage > 0) {
          return {
            yourSituation: this.translatePipe.transform('fast_lane.webk.text_green_flow_2', {
              weightChangePercentage,
              bmi,
            }),
          };
        }
        if (bmi >= 23 && weightChangePercentage > 0) {
          return {
            yourSituation: this.translatePipe.transform('fast_lane.webk.text_green_flow_3', {
              weightChangePercentage,
              bmi,
            }),
          };
        }
        if (weightChangePercentage === 0 && bmi >= 21 && bmi <= 23) {
          return {
            yourSituation: this.translatePipe.transform('fast_lane.webk.text_yellow_flow_1', {
              weightChangePercentage,
              bmi,
            }),
          };
        }
        if (weightChangePercentage === 0 && bmi >= 23 && bmi <= 25) {
          return {
            yourSituation: this.translatePipe.transform('fast_lane.webk.text_yellow_flow_2', {
              weightChangePercentage,
              bmi,
            }),
          };
        }
        if (weightChangePercentage === 0 && bmi > 25) {
          return {
            yourSituation: this.translatePipe.transform('fast_lane.webk.text_yellow_flow_3', {
              weightChangePercentage,
              bmi,
            }),
          };
        }
        if (weightChangePercentage === 0 && bmi < 21) {
          return {
            yourSituation: this.translatePipe.transform('fast_lane.webk.text_yellow_flow_4', {
              weightChangePercentage,
              bmi,
            }),
          };
        }
        if (weightChangePercentage < 0 && weightChangePercentage >= -5) {
          return {
            yourSituation: this.translatePipe.transform('fast_lane.webk.text_red_flow_1', {
              weightChangePercentage,
              bmi,
            }),
          };
        }
        if (weightChangePercentage >= -10 && weightChangePercentage < -5) {
          return {
            yourSituation: this.translatePipe.transform('fast_lane.webk.text_red_flow_2', {
              weightChangePercentage,
              bmi,
            }),
          };
        }
        if (weightChangePercentage >= -15 && weightChangePercentage < -10) {
          return {
            yourSituation: this.translatePipe.transform('fast_lane.webk.text_red_flow_3', {
              weightChangePercentage,
              bmi,
            }),
          };
        }
        if (weightChangePercentage < -15) {
          return {
            yourSituation: this.translatePipe.transform('fast_lane.webk.text_red_flow_4', {
              weightChangePercentage,
              bmi,
            }),
          };
        }
        return { yourSituation: this.translatePipe.transform('fast_lane.webk.default_flow_text') };
      })
    );
  }

  public handleDownloadResults() {
    combineLatest([this.data$, this.submissionResult$, this.language$])
      .pipe(
        take(1),
        tap(([data, submissionResult, language]) => {
          const coreData = this.user?.coreData;
          const mappedData: Record<string, string> = {};
          data.forEach((x) => {
            const options = x.options ?? [];
            mappedData[x.question] = this.translatePipe.transform(
              this.answerPipe.transform(x.answers[0], x.type, options)
            );
          });
          let createPdfFromResults;
          if (data.length === 10) {
            createPdfFromResults = createPdfFromResultsYellow;
          } else if (data.length === 15) {
            createPdfFromResults = createPdfFromResultsBaseline;
          } else if (data.length === 18) {
            createPdfFromResults = createPdfFromResultsRed;
          } else {
            throw new Error('Unexpected number of questions in data');
          }
          const pdfDoc = createPdfFromResults(
            mappedData,
            this.translatePipe.transform.bind(this.translatePipe),
            coreData,
            submissionResult,
            language
          );
          pdfMake
            .createPdf(pdfDoc)
            .download('esprio-screenning-' + moment(this.date).format('YYYY-MM-DD') + '.pdf');
        })
      )
      .subscribe();
  }
}